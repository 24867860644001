<template>
  <div class="lg:hidden">
    <button
      id="menubutton"
      class="ucrs-mobileMenu__toggle"
      aria-haspopup="true"
      aria-controls="menu"
      :aria-expanded="`${menuState.isOpen}`"
      :class="{
        'ring-offset-black-light': menuState.isOpen,
      }"
      aria-label="Menu di navigazione"
      @keydown.space.prevent="toggleMenu"
      @click="toggleMenu"
      @keydown.tab="handleKeydown($event, menuState.isOpen)"
    >
      <SvgIcon
        :name="menuState.isOpen ? 'close' : 'hamburger-menu'"
        class="ucrs-mobileMenu__icon"
        :aria-label="
          menuState.isOpen
            ? 'Chiudi menu di navigazione'
            : 'Apri menu di navigazione'
        "
      />
    </button>
    <div
      ref="menu"
      class="ucrs-mobileMenu"
      :class="{
        'ucrs-mobileMenu--visible': menuState.isOpen,
        'ucrs-mobileMenu--switchLevel': menuState.subListVisible,
      }"
      aria-live="polite"
      @keydown.tab="handleKeydown($event, menuState.isOpen)"
    >
      <div ref="firstLevel" class="ucrs-mobileMenu__firstLevel">
        <div class="container">
          <div class="ucrs-mobileMenu__flexContainer">
            <section
              class="ucrs-mobileMenu__navigation ucrs-mobileMenu__navigation--hasBorder"
              aria-labelledby="menubutton"
            >
              <SearchAutocomplete
                class="w-full mb-8 px-2 focus-visible:ring-offset-black"
                @select="closeMenu"
              />
              <ul id="menu" class="ucrs-mobileMenu__list" role="menu">
                <li role="none">
                  <div class="ucrs-mobileMenu__link" aria-expanded="true">
                    <SvgIcon
                      aria-hidden="true"
                      name="sell-white"
                      class="ucrs-mobileMenu__icon mr-6"
                    />
                    <span class="ucrs-mobileMenu__label">Vendo</span>
                    <SvgIcon
                      aria-hidden="true"
                      name="angle-down-white"
                      class="ucrs-mobileMenu__icon--sm ml-6"
                    />
                  </div>
                  <ul
                    class="ucrs-mobileMenu__list pl-16 pt-10"
                    role="menu"
                    aria-label="sottomenu Vendo"
                  >
                    <li
                      v-for="(subItem, i) in vendoItems"
                      :key="`vendo-${i}`"
                      class="ucrs-mobileMenu__item"
                      role="none"
                    >
                      <NuxtLink
                        v-dompurify-html="subItem.text"
                        role="menuitem"
                        class="ucrs-mobileMenu__label"
                        :to="subItem.href"
                      ></NuxtLink>
                    </li>
                  </ul>
                </li>
                <li
                  v-for="item in items"
                  :key="item.index"
                  class="ucrs-mobileMenu__item"
                  role="none"
                >
                  <button
                    v-if="item.children"
                    class="ucrs-mobileMenu__link"
                    aria-haspopup="true"
                    :aria-expanded="
                      menuState.subListVisible && secondLevelItem === item
                    "
                    @click="handleSecondLevelMenu(item)"
                  >
                    <SvgIcon
                      :name="item.iconMobile"
                      class="ucrs-mobileMenu__icon mr-6"
                      aria-hidden="true"
                    />
                    {{ item.textMobile }}
                    <SvgIcon
                      name="angle-right-white"
                      class="ucrs-mobileMenu__icon--sm ml-6"
                      aria-hidden="true"
                    />
                  </button>
                  <NuxtLink
                    v-else
                    class="ucrs-mobileMenu__link"
                    :to="item.href"
                    role="menuitem"
                  >
                    <SvgIcon
                      :name="item.iconMobile"
                      class="ucrs-mobileMenu__icon mr-6"
                      aria-hidden="true"
                    />
                    {{ item.text }}
                  </NuxtLink>
                </li>
              </ul>
            </section>
          </div>
        </div>
      </div>
      <div ref="secondLevel" class="ucrs-mobileMenu__secondLevel">
        <div class="container">
          <section class="ucrs-mobileMenu__navigation">
            <button
              aria-label="Torna indietro"
              class="ucrs-mobileMenu__back uppercase"
              @click="returnToFirstLevel"
            >
              <SvgIcon
                aria-hidden="true"
                name="angle-left-white"
                class="ucrs-mobileMenu__icon--sm mr-4"
              />
              Back
            </button>
            <p
              class="ucrs-mobileMenu__label ucrs-mobileMenu__label--listTitle"
              v-text="secondLevelItem.textMobile"
            ></p>
            <ul class="ucrs-mobileMenu__list">
              <li
                v-for="(subItem, i) in secondLevelItem.children"
                :key="i"
                class="ucrs-mobileMenu__item"
                role="none"
              >
                <NuxtLink
                  v-dompurify-html="subItem.text"
                  role="menuitem"
                  class="ucrs-mobileMenu__label"
                  :to="subItem.href"
                ></NuxtLink>
              </li>
            </ul>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MobileNav from '~/mixins/mobile-nav'
import SearchAutocomplete from '~/components/SearchAutocomplete.vue'

export default {
  components: {
    SearchAutocomplete,
  },
  mixins: [MobileNav],
  data() {
    return {
      menuState: {
        isOpen: false,
        subListVisible: false,
        activeItem: null,
      },
      vendoItems: this.$utilities.navigation.vendo.children,
      items: [
        this.$utilities.navigation.cerco,
        this.$utilities.navigation.serviziDocumenti,
        this.$utilities.navigation.contatti,
        this.$utilities.navigation.chisiamo,
        this.$utilities.navigation.news,
      ],
      secondLevelItem: {},
      focusableElements: [],
      toggleButtonId: 'menubutton',
    }
  },
  watch: {
    $route() {
      this.closeMenu()
    },
    'menuState.isOpen'(isOpen) {
      if (!isOpen) return
      this.setFocusableElements('firstLevel')
    },
    'menuState.subListVisible'(subListVisible) {
      if (!subListVisible && !this.menuState.isOpen) return

      const menuRefName = subListVisible ? 'secondLevel' : 'firstLevel'
      this.setFocusableElements(menuRefName)
    },
  },
  methods: {
    setFocusableElements(refName) {
      const refEl = this.$refs[refName]

      this.$nextTick(() => {
        this.focusableElements = refEl.querySelectorAll('a, button, input')
      })
    },
    toggleMenu() {
      if (this.menuState.isOpen) {
        this.closeMenu()
        return
      }
      this.menuState.isOpen = true
    },
    closeMenu() {
      this.menuState.isOpen = false
      this.menuState.subListVisible = false
      this.secondLevelItem = {}
    },
    handleSecondLevelMenu(item) {
      this.menuState.subListVisible = true
      this.secondLevelItem = item
      this.$nextTick(() => {
        const backButton = this.$el.querySelector(
          '.ucrs-mobileMenu__secondLevel .ucrs-mobileMenu__back'
        )
        if (backButton) {
          backButton.focus()
        }
      })
    },
    returnToFirstLevel() {
      this.menuState.subListVisible = false
      this.$nextTick(() => {
        const firstItem = this.$el.querySelector(
          '.ucrs-mobileMenu__firstLevel .ucrs-mobileMenu__item a'
        )
        if (firstItem) {
          firstItem.focus()
        }
      })
    },
  },
}
</script>

<style lang="postcss">
@import '~/assets/css/components/mobile-nav';
</style>
