export default {
  methods: {
    handleKeydown(event, isOpen) {
      if (!isOpen) return

      const toggleButton = this.$el.querySelector(`#${this.toggleButtonId}`)
      const lastFocusable = this.focusableElements[
        this.focusableElements.length - 1
      ]
      const firstFocusable = this.focusableElements[0]

      switch (true) {
        case document.activeElement === firstFocusable && !event.shiftKey:
          event.preventDefault()
          this.focusableElements[1].focus()
          break
        case document.activeElement === firstFocusable && event.shiftKey:
          event.preventDefault()
          toggleButton.focus()
          break
        case document.activeElement === toggleButton && !event.shiftKey:
          event.preventDefault()
          firstFocusable.focus()
          break
        case document.activeElement === toggleButton && event.shiftKey:
          event.preventDefault()
          lastFocusable.focus()
          break
        case document.activeElement === lastFocusable && !event.shiftKey:
          event.preventDefault()
          toggleButton.focus()
      }
    },
  },
}
